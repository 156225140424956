.text-div {
    width: 100%;
    max-width: 600px;
    
    border-radius: 10px;
    /* Dynamic height based on content */
    height: auto;
    /* Animation */
    animation: spawnAndGrow 0.9s ease-out forwards;
    font-family: Arial, Helvetica, sans-serif;
 
  }
  
  @keyframes spawnAndGrow {
    from {
      opacity: 0;
      transform: scale(0.95);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }

ul.custom-list {
  list-style-type: none;
  padding: 0;
}
  ul.custom-list li {
    padding-left: 20px; /* Make room for the image */
    background-repeat: no-repeat;
    background-position: 0 5%;
    background-size: 15px 15px; /* Example size, adjust as needed */
    background-image: url('cookie.png'); /* Path to your image */
  }
  .loading-bar-container {
    width: 100%;
    height: 40px; /* Adjust height as needed */
    background-color: #f0f0f0; /* Background color of the loading bar container */
    position: relative;
  }
  
  .loading-bar {
    width: 0; /* Initial width of the loading bar */
    height: 100%;
    background-color: #007bff; /* Color of the loading bar */
    position: absolute;
    top: 0;
    left: 0;
    animation: loading 16s linear forwards; /* Adjust animation duration as needed */
    border-radius: 4px;
  }

  .load-container{

    width: 80%;
    max-width: 600px;
  }
  
  @keyframes loading {
    0% {
      width: 0; /* Start position */
    }
    100% {
      width: 100%; /* End position */
    }
  }
  

  .ingredients, .steps{
    margin-bottom: 10px;
  }